import { library } from '@fortawesome/fontawesome-svg-core';

import { faWarehouse, faQuestion, faSpinner, faCode } from '@fortawesome/free-solid-svg-icons';

const buildIconLibrary = () => {
  library.add(
    faWarehouse,
    faQuestion,
    faSpinner,
    faCode,
  );
};

export default buildIconLibrary;
