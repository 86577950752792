import React, { Component } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import CreateSet from './CreateSet';
import LegoSetList from './LegoSetList';
import SetDetails from './SetDetails';

class LegoRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.path}/sets/new`} component={CreateSet} />
        <Route exact path={`${match.path}/sets/:id`} component={SetDetails} />
        <Route exact path={`${match.path}/sets`} component={LegoSetList} />
        <Redirect from={`${match.path}`} to={`${match.path}/sets`} />
      </Switch>
    );
  }
}

LegoRoutes.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default LegoRoutes;
