import { Breadcrumbs, Link, Typography, makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';

import { fetchSetSummaries } from './LegoActions';
import Spinner from 'common/Spinner';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  link: {
    display: 'block',
    marginBottom: theme.spacing(1),
  },
}));

export default function LegoSetList() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isLoadingSetSummaries, setSummaries } = useSelector(state => state.lego);

  useEffect(() => {
    dispatch(fetchSetSummaries());
  }, [dispatch]);

  let content;
  if (isLoadingSetSummaries) {
    content = <Spinner />;
  } else if (setSummaries.length === 0) {
    content = (
      <Typography>
        You don't have any sets,&nbsp;
        <Link component={RouterLink} to="/apps/lego/sets/new">go create one!</Link>
      </Typography>
    );
  } else {
    console.log('##### setSummaries', setSummaries);
    content = (
      <Typography>
        {setSummaries.map(set => (
          <Link key={set.id} className={classes.link} component={RouterLink} to={`/apps/lego/sets/${set.id}`}>{set.name}</Link>
        ))}
        <Link className={classes.link} component={RouterLink} to="/apps/lego/sets/new">Create a new one!</Link>
      </Typography>
    );
  }

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} color="inherit" to="/apps">
          Apps
        </Link>>
        <Typography color="textPrimary">Lego</Typography>
      </Breadcrumbs>
      <Typography variant="h1" gutterBottom>My Sets</Typography>
      {content}
    </>
  );
}