import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'flex-end',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%',
  },
}));

export default function Placeholder({ src, children }) {
  const classes = useStyles();
  return (
    <div className={classes.container} style={{ backgroundImage: `url(${src})` }}>
      {children}
    </div>
  );
}

Placeholder.propTypes = {
  src: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
