import {
  Breadcrumbs,
  FormGroup,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Paper,
  Switch,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Add, Check, Remove } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import { fetchSet, updateSet } from './LegoActions';
import Spinner from 'common/Spinner';

const styles = theme => ({
  paperRoot: {
    padding: theme.spacing(2),
    maxWidth: '100%',
  },
  header: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    maxWidth: '100%',
  },
  image: {
    marginBottom: theme.spacing(2),
    maxWidth: '100%',
  },
  partImage: {
    marginBottom: theme.spacing(2),
    maxWidth: '100%',
    maxHeight: 150,
  },
  colorLabel: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: 50,
      height: 50,
      marginRight: theme.spacing(1),
    },
  },
});

class SetDetails extends Component {
  state = {
    id: null,
    showSpareParts: false,
    showCompletedParts: true,
  };

  componentDidMount() {
    const { sets, match } = this.props;
    const id = match.params.id;
    this.setState({ id });

    if (!sets[id]) {
      this.props.fetchSet(id);
    }
  }

  changePartCount = (set, part, changeBy) => {
    const updatedPart = {
      ...part,
      quantityFound: part.quantityFound + changeBy,
    };

    const partIndex = set.parts.indexOf(part);
    const parts = [...set.parts];
    parts[partIndex] = updatedPart;
    const updatedSet = { ...set, parts };

    console.log(updatedSet);
    this.props.updateSet(updatedSet);
  }

  handleSwitchChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  renderSet(set) {
    if ((!set && !set.parts) || set.parts.length === 0) {
      return <Typography color="error">Something's wrong</Typography>;
    }

    console.log(set);
    const { showCompletedParts, showSpareParts } = this.state;
    const { classes } = this.props;
    const numPartsFound = set.parts.reduce((sum, part) => sum + part.quantityFound, 0);

    return (
      <>
        <Typography variant="h1" gutterBottom>{set.name} ({set.year})</Typography>
        <img className={classes.image} src={set.setImgUrl} alt="Box Cover" />
        <Typography variant="h2" gutterBottom>{set.numParts} Parts ({numPartsFound} Found)</Typography>

        <FormGroup row>
          <FormControlLabel
            control={
              <Switch checked={showCompletedParts} onChange={this.handleSwitchChange('showCompletedParts')} value="showCompletedParts" />
            }
            label="Show Completed Parts"
          />
          <FormControlLabel
            control={
              <Switch checked={showSpareParts} onChange={this.handleSwitchChange('showSpareParts')} value="showSpareParts" />
            }
            label="Show Spare Parts"
          />
        </FormGroup>

        <Grid container>
          {set.parts.filter(part => {
            if (!showSpareParts && part.isSpare) {
              return false;
            } else if (part.quantityFound >= part.quantity && !showCompletedParts) {
              return false;
            }
            return true;
          }).map(part => (
            <Grid key={part.id} item xs={12} sm={6} md={4} lg={3}>
              <Paper classes={{ root: classes.paperRoot }}>
                <Typography variant="h3" gutterBottom>{part.part.name}{part.isSpare ? ' (spare)' : ''}</Typography>
                <Typography align="center" component="div" gutterBottom>
                  <img className={classes.partImage} src={part.part.partImgUrl} alt="Part" />
                </Typography>
                <Typography gutterBottom component="div" className={classes.colorLabel}>
                  <div style={{ backgroundColor: `#${part.color.rgb}` }} />
                  {part.color.name}
                </Typography>

                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography>Quantity: {part.quantity}</Typography>
                    <Typography>Found: {part.quantityFound}</Typography>
                  </Grid>
                  {part.quantity === part.quantityFound &&
                    <Grid item>
                      <Check fontSize="large" color="primary" htmlColor="#4CAF50" />
                    </Grid>
                  }
                  <Grid item>
                    <IconButton
                      variant="contained"
                      color="primary"
                      size="medium"
                      disabled={part.quantityFound >= part.quantity}
                      onClick={() => this.changePartCount(set, part, 1)}
                    >
                      <Add />
                    </IconButton>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="medium"
                      disabled={part.quantityFound <= 0}
                      onClick={() => this.changePartCount(set, part, -1)}
                    >
                      <Remove />
                    </IconButton>
                  </Grid>
                </Grid>


                {/* <code><pre>{JSON.stringify(part, null, 2)}</pre></code> */}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  render() {
    const { sets, isLoadingSet } = this.props;
    const { id } = this.state;
    const set = sets[id];

    let content;
    if (isLoadingSet) {
      content = <Spinner />;
    } else if (set) {
      content = this.renderSet(set);
    } else {
      // TODO
    }

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} color="inherit" to="/apps">
            Apps
          </Link>
          <Link component={RouterLink} color="inherit" to="/apps/lego">
            Lego
          </Link>
          <Typography color="textPrimary">Set</Typography>
        </Breadcrumbs>
        {content}
      </>
    );
  }
}

SetDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  sets: PropTypes.object.isRequired,
  isLoadingSet: PropTypes.bool.isRequired,
  fetchSet: PropTypes.func.isRequired,
  updateSet: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const { sets, isLoadingSet } = state.lego;
  return {
    sets,
    isLoadingSet,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    fetchSet,
    updateSet,
  }),
)(SetDetails);
