import { Grow, Link, Typography, makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

import BackgroundImage from 'common/BackgoundImage';
import image from 'assets/single_lego_background.jpg';

const useStyles = makeStyles(theme => ({
  textContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
}));

export default function Placeholder() {
  const classes = useStyles();
  return (
    <BackgroundImage src={image}>
      <Grow in timeout={5000}>
        <div className={classes.textContainer}>
          <Typography variant="h1">Work in progress...</Typography>
          <Link component={RouterLink} to="/about">About</Link>
        </div>
      </Grow>
    </BackgroundImage>
  );
}
