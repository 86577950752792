import {
  CssBaseline,
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';

import About from 'about/About';
import AppRoutes from './AppRoutes';
import Placeholder from './Placeholder';

const globalTheme = createMuiTheme({
  palette: {
    primary: {
      contrastText: '#FFFFFF',
      dark: '#455A64',
      light: '#CFD8DC',
      main: '#607D8B',
    },
    secondary: {
      contrastText: '#FFFFFF',
      main: '#FF5722',
    },
  },
  props: {
    MuiGrid: {
      spacing: 2,
    },
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontSize: '4rem',
    },
    h2: {
      fontSize: '3rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
  },
});

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
  },
}));

export default function MainLayout() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <MuiThemeProvider theme={globalTheme}>
        <CssBaseline />
        <Switch>
          <Route exact path="/" component={Placeholder} />
          <Route exact path="/about" component={About} />
          <Route path="/apps" component={AppRoutes} />
          <Redirect from="*" to="/" />
        </Switch>
      </MuiThemeProvider>
    </div>
  );
};
