import {
  AppBar,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import AccountCircle from '@material-ui/icons/AccountCircle';
import React, { useState } from 'react';

import Spinner from 'common/Spinner';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import auth from 'auth/Auth';

const useStyles = makeStyles(theme => ({
  logoIcon: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function AppRoutes() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { isLoading, user } = useSelector(state => state.auth);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    auth.logout();
    handleClose();
  };

  return (
    <Container maxWidth="lg" classes={{ root: classes.root }}>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar disableGutters>
          <FontAwesomeIcon className={classes.logoIcon} icon="code" size="2x" />
          <Typography variant="h6" className={classes.title}>
            Nonsimultaneous
          </Typography>
          {user && (
            <div>
              <IconButton onClick={handleMenu} color="inherit">
                <AccountCircle />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </div>
          )}
          {!user && !isLoading && (
            <Button onClick={auth.login}>Login</Button>
          )}
        </Toolbar>
      </AppBar>
      {user && <AuthenticatedRoutes />}
      {!user && <Typography>Nothing to do until you log in...</Typography>}
      {isLoading && <Spinner />}
    </Container>
  );
}
