import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(2),
  },
}));

const Spinner = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <FontAwesomeIcon icon="spinner" size="4x" spin />
    </div>
  );
}

export default Spinner;
