import { Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';

import AppList from './AppList';
import LegoRoutes from 'lego/LegoRoutes';

export default function AuthenticatedRoutes() {
  return (
    <Switch>
      <Route path="/apps" exact component={AppList} />
      <Route path="/apps/lego" component={LegoRoutes} />
      <Route path="/apps/other" render={() => <h1>OTHER</h1>} />
      <Redirect from="*" to="/apps" />
    </Switch>
  );
}

