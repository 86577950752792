import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import AuthListener from 'auth/AuthListener';
import MainLayout from 'layout/MainLayout';

const App = ({ store }) => (
  <Provider store={store}>
    <AuthListener>
      <Route path="/" component={MainLayout} />
    </AuthListener>
  </Provider>
);

App.propTypes = {
  store: PropTypes.object.isRequired,
};

export default App;
