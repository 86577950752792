export const LEGO_CREATE_SET_REQUEST = 'LEGO_CREATE_SET_REQUEST';
export const LEGO_CREATE_SET_SUCCESS = 'LEGO_CREATE_SET_SUCCESS';
export const LEGO_CREATE_SET_FAILURE = 'LEGO_CREATE_SET_FAILURE';

export const LEGO_FETCH_SET_SUMMARIES_REQUEST = 'LEGO_FETCH_SET_SUMMARIES_REQUEST';
export const LEGO_FETCH_SET_SUMMARIES_SUCCESS = 'LEGO_FETCH_SET_SUMMARIES_SUCCESS';
export const LEGO_FETCH_SET_SUMMARIES_FAILURE = 'LEGO_FETCH_SET_SUMMARIES_FAILURE';

export const LEGO_FETCH_SET_REQUEST = 'LEGO_FETCH_SET_REQUEST';
export const LEGO_FETCH_SET_SUCCESS = 'LEGO_FETCH_SET_SUCCESS';
export const LEGO_FETCH_SET_FAILURE = 'LEGO_FETCH_SET_FAILURE';

export const LEGO_UPDATE_SET_REQUEST = 'LEGO_UPDATE_SET_REQUEST';
export const LEGO_UPDATE_SET_SUCCESS = 'LEGO_UPDATE_SET_SUCCESS';
export const LEGO_UPDATE_SET_FAILURE = 'LEGO_UPDATE_SET_FAILURE';
