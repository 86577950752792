import * as firebase from 'firebase/app';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setUser, clearUser } from './AuthActions';

class AuthListener extends Component {

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      console.log('firebase auth state changed', user);
      if (user) {
        this.props.setUser(user);
      } else {
        this.props.clearUser();
      }
    });
  }

  render() {
    return this.props.children;
  }
}

AuthListener.propTypes = {
  children: PropTypes.node.isRequired,
  setUser: PropTypes.func.isRequired,
  clearUser: PropTypes.func.isRequired,
};

export default connect(null, {
  setUser,
  clearUser,
})(AuthListener);

