import * as firebase from 'firebase/app';

class Auth {
  constructor() {
    this.provider = new firebase.auth.GoogleAuthProvider();
  }

  login = async () => {
    try {
      const { user } = await firebase.auth().signInWithPopup(this.provider);
      return user;
    } catch (err) {
      return null;
    }
  };

  logout = () => {
    return firebase.auth().signOut();
  };

  getIdToken = () => {
    const { currentUser } = firebase.auth();
    return currentUser ? currentUser.getIdToken() : null;
  };
}

export default new Auth();
