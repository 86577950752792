import { combineReducers } from 'redux';

import auth from 'auth/AuthReducer.js';
import layout from 'layout/LayoutReducer.js';
import lego from 'lego/LegoReducer.js';

const rootReducer = combineReducers({
  auth,
  layout,
  lego,
});

export default rootReducer;
