import produce from 'immer';

import { CLEAR_USER } from 'auth/types';
import {
  LEGO_CREATE_SET_REQUEST, LEGO_CREATE_SET_SUCCESS, LEGO_CREATE_SET_FAILURE,
  LEGO_FETCH_SET_REQUEST, LEGO_FETCH_SET_SUCCESS, LEGO_FETCH_SET_FAILURE,
  LEGO_FETCH_SET_SUMMARIES_REQUEST, LEGO_FETCH_SET_SUMMARIES_SUCCESS, LEGO_FETCH_SET_SUMMARIES_FAILURE,
} from './types';

const initialState = {
  sets: {},
  isCreatingSet: false,
  isLoadingSet: false,
  syncing: [],
  isLoadingSetSummaries: false,
  setSummaries: [],
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case LEGO_CREATE_SET_REQUEST:
      draft.isCreatingSet = true;
      break;

    case LEGO_CREATE_SET_SUCCESS:
      draft.sets[action.payload.id] = action.payload;
      draft.isCreatingSet = false;
      break;

    case LEGO_CREATE_SET_FAILURE:
      draft.isCreatingSet = false;
      break;

    case LEGO_FETCH_SET_REQUEST:
      draft.isLoadingSet = true;
      draft.syncing.push(action.payload.id);
      break;

    case LEGO_FETCH_SET_SUCCESS:
      draft.isLoadingSet = false;
      draft.sets[action.payload.id] = action.payload;
      break;

    case LEGO_FETCH_SET_FAILURE:
      draft.isLoadingSet = false;
      draft.syncing = draft.syncing.filter(id => id !== action.payload.id);
      break;

    case LEGO_FETCH_SET_SUMMARIES_REQUEST:
      draft.isLoadingSetSummaries = true;
      break;

    case LEGO_FETCH_SET_SUMMARIES_SUCCESS:
      draft.isLoadingSetSummaries = false;
      draft.setSummaries = action.payload;
      break;

    case LEGO_FETCH_SET_SUMMARIES_FAILURE:
      draft.isLoadingSetSummaries = false;
      break;

    case CLEAR_USER:
      return initialState;

    default:
      break;
  }

  return draft;
}, initialState);
