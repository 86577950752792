import { Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

export default function AppList() {
  return (
    <>
      <Typography variant="h1" gutterBottom>App List</Typography>
      <Link component={RouterLink} to="/apps/lego">Lego Part Tracker</Link>
    </>
  );
};

