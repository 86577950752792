import {
  SET_PAGE_TITLE,
  HIDE_TOAST_MESSAGE, SHOW_TOAST_MESSAGE,
} from './types';

export const setPageTitle = title => {
  return {
    type: SET_PAGE_TITLE,
    payload: title,
  };
};

let toastTimeout;
export const showToastMessage = (message, autodismiss = true) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_TOAST_MESSAGE,
      payload: message,
    });

    if (autodismiss) {
      clearTimeout(toastTimeout);
      toastTimeout = setTimeout(() => {
        dispatch(hideToastMessage());
      }, 3000);
    }
  };
};

export const hideToastMessage = () => {
  return {
    type: HIDE_TOAST_MESSAGE,
  };
};

