import { Card, CardContent, CardHeader, Link, Typography, Zoom, makeStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Parser } from 'html-to-react';
import React from 'react';

import attributions from './attributions';
import BackgroundImage from 'common/BackgoundImage';
import image from 'assets/lego_pile_background.jpg';

const parser = new Parser();
const imageAttributions = attributions.images.map(parser.parse);

const useStyles = makeStyles(theme => ({
  imageAttributionsContainer: {
    '&> span': {
      margin: theme.spacing(1),
      display: 'inline-block',
    },
  },
}));

export default function Placeholder() {
  const classes = useStyles();
  return (
    <BackgroundImage src={image}>
      <Zoom in timeout={1000}>
        <div className="center-container">
          <Card>
            <CardHeader
              title="About"
              subheader={<Link component={RouterLink} to="/">Home</Link>}
            />
            <CardContent>
              <Typography gutterBottom>This is where I eventually write what this site is for.</Typography>
              <Typography gutterBottom>For now, let's just roll the credits.</Typography>
              <Typography variant="h6">Image Attributions</Typography>
              <div className={classes.imageAttributionsContainer}>
                {imageAttributions.map((attribution, i) => <span key={i}>{attribution}</span>)}
              </div>
            </CardContent>
          </Card>
        </div>
      </Zoom>
    </BackgroundImage>
  );
}
