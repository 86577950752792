import * as firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { render } from 'react-dom';
import React from 'react';
import * as serviceWorker from './serviceWorker';

import './index.css';
import App from './App';
import buildIconLibrary from './iconLoader';
import configureStore from './configureStore';

const history = createBrowserHistory();
const basename = process.env.PUBLIC_URL || '/';
const store = configureStore();
buildIconLibrary();

const firebaseConfig = {
  apiKey: 'AIzaSyCiQe9eykDEut3aVB6qz31gFhXQCpj0qIM',
  authDomain: 'nonsimultaneous.firebaseapp.com',
  databaseURL: 'https://nonsimultaneous.firebaseio.com',
  projectId: 'nonsimultaneous',
  storageBucket: 'nonsimultaneous.appspot.com',
  messagingSenderId: '313501819478',
  appId: '1:313501819478:web:3dec094bac66a656',
};

firebase.initializeApp(firebaseConfig);

const Root = () => (
  <Router basename={basename} history={history}>
    <App store={store} />
  </Router>
);

if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', 'nsml:*');
}

render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

console.log('deployment test');
