import { Breadcrumbs, Button, Link, TextField, Typography, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import { showToastMessage } from 'layout/LayoutActions';

import { createSet } from './LegoActions';
import { LEGO_CREATE_SET_FAILURE } from './types';

const styles = theme => ({
  form: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textField: {
    marginRight: theme.spacing(4),
  },
});

class CreateSet extends Component {
  state = {
    setNumber: '',
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { type, payload } = await this.props.createSet(this.state.setNumber);
    if (type === LEGO_CREATE_SET_FAILURE) {
      this.props.showToastMessage('Something went wrong, please check the set number and try again');
    } else {
      this.props.history.push(`/apps/lego/sets/${payload.id}`);
    }
  };

  render() {
    const { classes, isCreatingSet } = this.props;
    const { setNumber } = this.state;

    return (
      <>
        <Breadcrumbs aria-label="breadcrumb">
          <Link component={RouterLink} color="inherit" to="/apps">
            Apps
          </Link>
          <Link component={RouterLink} color="inherit" to="/apps/lego">
            Lego
          </Link>
          <Typography color="textPrimary">Create</Typography>
        </Breadcrumbs>
        <Typography variant="h1" gutterBottom>Create</Typography>
        <form noValidate className={classes.form} autoComplete="off" onSubmit={this.handleSubmit}>
          <TextField
            label="Set Number"
            className={classes.textField}
            value={setNumber}
            onChange={this.handleChange('setNumber')}
            disabled={isCreatingSet}
          />
          <Button type="submit" disabled={isCreatingSet} variant="contained" color="primary" size="large">
            {isCreatingSet && <FontAwesomeIcon icon="spinner" spin size="2x" />}
            {!isCreatingSet && 'Create'}
          </Button>
        </form>
      </>
    );
  }
}

CreateSet.propTypes = {
  classes: PropTypes.object.isRequired,
  isCreatingSet: PropTypes.bool.isRequired,
  createSet: PropTypes.func.isRequired,
  showToastMessage: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

const mapStateToProps = state => {
  const { isCreatingSet } = state.lego;
  return {
    isCreatingSet,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    createSet,
    showToastMessage,
  })
)(CreateSet);
