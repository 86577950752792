import produce from 'immer';

import {
  SET_PAGE_TITLE,
  HIDE_TOAST_MESSAGE, SHOW_TOAST_MESSAGE,
} from './types';

const initialState = {
  title: 'Nonsimultaneous',
  toastMessage: null,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_PAGE_TITLE:
      draft.title = action.payload;
      break;

    case SHOW_TOAST_MESSAGE:
      draft.toastMessage = action.payload;
      break;

    case HIDE_TOAST_MESSAGE:
      draft.toastMessage = null;
      break;

    default:
      break;
  }

  return draft;
}, initialState);
