import produce from 'immer';

import { SET_USER, CLEAR_USER } from './types';

const initialState = {
  user: null,
  isLoading: true,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      draft.user = action.payload;
      draft.isLoading = false;
      break;

    case CLEAR_USER:
      draft.user = null;
      draft.isLoading = false;
      break;

    default:
      break;
  }

  return draft;
}, initialState);
