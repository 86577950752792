import axios from 'axios';
import * as firebase from 'firebase/app';

import {
  LEGO_CREATE_SET_REQUEST, LEGO_CREATE_SET_SUCCESS, LEGO_CREATE_SET_FAILURE,
  LEGO_FETCH_SET_REQUEST, LEGO_FETCH_SET_SUCCESS, LEGO_FETCH_SET_FAILURE,
  LEGO_FETCH_SET_SUMMARIES_REQUEST, LEGO_FETCH_SET_SUMMARIES_SUCCESS, LEGO_FETCH_SET_SUMMARIES_FAILURE,
  LEGO_UPDATE_SET_REQUEST, LEGO_UPDATE_SET_SUCCESS, LEGO_UPDATE_SET_FAILURE,
} from './types';
import auth from 'auth/Auth';

const BASE_URL = 'https://us-central1-nonsimultaneous.cloudfunctions.net'; //initSet

export const createSet = setNumber => async dispatch => {
  dispatch({ type: LEGO_CREATE_SET_REQUEST, payload: { setNumber }});
  try  {
    const token = await auth.getIdToken();
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    const response = await axios.post(`${BASE_URL}/initSet`, {  setNumber }, { headers });
    const action = { type: LEGO_CREATE_SET_SUCCESS, payload: response.data };
    dispatch(action);
    return action;
  } catch (error) {
    const action = { type: LEGO_CREATE_SET_FAILURE, payload: { setNumber, error }};
    dispatch(action);
    return action;
  }
};

export const fetchSetSummaries = () => async (dispatch, getState) => {
  const { lego } = getState();
  if (lego.isLoadingSetSummaries) {
    dispatch({ type: LEGO_FETCH_SET_SUMMARIES_FAILURE, payload: { error: 'Already fetching set summaries' }});
    return;
  }

  dispatch({ type: LEGO_FETCH_SET_SUMMARIES_REQUEST });
  try  {
    const token = await auth.getIdToken();
    const headers = token ? { Authorization: `Bearer ${token}` } : {};
    const response = await axios.get(`${BASE_URL}/setList`, { headers });
    if (response.status === 200) {
      dispatch({ type: LEGO_FETCH_SET_SUMMARIES_SUCCESS, payload: response.data });
    } else {
      throw new Error('Failed to fetch sets');
    }
  } catch (error) {
    dispatch({ type: LEGO_FETCH_SET_SUMMARIES_FAILURE, payload: { error }});
  }
};

export const fetchSet = id => async (dispatch, getState) => {
  if (getState().lego.syncing.includes(id)) {
    dispatch({ type: LEGO_FETCH_SET_FAILURE, payload: { id, error: 'Already synchronizing this set' }});
    return;
  }

  dispatch({ type: LEGO_FETCH_SET_REQUEST, payload: { id } });
  try  {
    const db = firebase.firestore();
    // TODO this allows fetching any set if you know the id
    const unsubscribe = db.collection('sets').doc(id).onSnapshot(doc => {
      console.log('SNAPSHOT CHANGED', doc.data());
      dispatch({ type: LEGO_FETCH_SET_SUCCESS, payload: { id, ...doc.data(), unsubscribe }});
    });
  } catch (error) {
    dispatch({ type: LEGO_FETCH_SET_FAILURE, payload: { id, error }});
  }
};

export const updateSet = set => async (dispatch, getState) => {
  dispatch({ type: LEGO_UPDATE_SET_REQUEST });
  // eslint-disable-next-line no-unused-vars
  const { id, unsubscribe, ...rest} = set;
  try {
    const db = firebase.firestore();
    await db.collection('sets').doc(set.id).update(rest);
    dispatch({ type: LEGO_UPDATE_SET_SUCCESS });
  } catch (error) {
    dispatch({ type: LEGO_UPDATE_SET_FAILURE, payload: { error }});
  }
};

// export const startSyncingSets = () => async (dispatch, getState) => {
//   if (getState().lego.isSyncingSets) {
//     return;
//   }

//   dispatch({ type: LEGO_SYNC_SETS_START });
//   try {
//     const userId = '01234567890'; // TODO
//     firebase.database().ref(`/${userId}/sets`).on('child_added', data => {

//     });
//     firebase.database().ref(`/${userId}/sets`).on('child_changed', data => {

//     });
//     firebase.database().ref(`/${userId}/sets`).on('child_removed', data => {

//     });
//   } catch (error) {
//     dispatch({ type: LEGO_SYNC_SETS_FAILURE, payload: { error }});
//   }
// };